import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {navigate} from 'gatsby';

import Section from '../../components/section';
import Layout from '../../components/layout';
import HomePageIntegrations from '../../components/hompage-integration';
import Button from '../../components/form/button';

import nurture from '../../images/home/nurture-leads.png';
import scale from '../../images/home/scale.png';
import compose from '../../images/home/compose.png';
import findLeads from '../../images/home/find-leads.png';
import join from '../../images/home/join.png';
import sayGoodbye from '../../images/home/say-goodbye.png';
import lego from '../../images/home/lego-item.svg';
import nurtureLeads from '../../images/home/nurture-leads.svg';
import automate from '../../images/home/automate.svg';
import person from '../../images/home/person.svg';
import unified from '../../images/home/unified.svg';
import hexomatic from '../../images/home/hexomatic.svg';
import hexowatch from '../../images/home/hexowatch.svg';
import hexometer from '../../images/home/hexometer.svg';
import email from '../../images/home/email.svg';
import save from '../../images/home/automate.png';

import '../styles.scss';
import { openCalendly } from '../../helpers/trackingEvents';

const LIfetimeSpecial = () => {
  const handleClick = () => {
    window.location.href = 'https://dash.hexospark.com';
  };

  useEffect(() => {
    navigate('/pricing/');
  }, []);
  
  return (
    <Layout promoPage>
      <div className="home-container">
        {' '}
        <Section homePage lightGrey pageheroPading lgPd>
          <Col lg={6} className="align-self-center">
            <h1 className="text-mobile">
              {/* Outbound  */}
              <div>Personalized email outreach</div>
              <div>
                at scale,
                <span className="primary-color"></span>
                <span className="primary-color"> made easy</span>
              </div>
            </h1>
            <img src={join} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />
            <p className="text-mobile"> Find more leads, get better replies, and grow your revenue on autopilot. </p>
            {/* <Subscribe btnText="JOIN THE WAITLIST" /> */}
            <div className="w-100 mb-3 text-mobile">
              <Button btnText={'Get started'} onClick={handleClick} className="get-started" />
            </div>
            {/* <div className="w-100 mb-3 text-mobile">
              <Button btnText={'Request a demo'} onClick={openCalendly} className="get-started" outline />
            </div> */}
            <div className="d-flex products-wrapper col-sm-6">
              <span>Brought to you by the makers of</span>

              <div className="icon-wrapper">
                <img src={hexomatic} alt="hexomatic" /> <img src={hexowatch} alt="hexowatch" />{' '}
                <img src={hexometer} alt="hexometer" />
              </div>
            </div>
          </Col>
          <Col lg={6} className="d-none d-lg-flex">
            <img src={join} alt="hexospark.com" className="section-pagehero-image" />
          </Col>
        </Section>
        <Section homePage lightGrey>
          <div className="center text-center">
            <h2>Say goodbye to empty sales pipelines and low reply rates</h2>
            <p className="subTitle m-auto w-100%">
              Finally, an all-in-one platform that makes it easy to find and nurture leads with personalized emails that
              prospects will love. At scale 🚀.
            </p>
          </div>
        </Section>
        <Section homePage lightGrey>
          <Row className="align-center justify-center">
            <Col className="col-12 col-lg-6 align-self-center">
              <div className="d-lg-flex mb-3 text-mobile">
                <img src={email} alt="lego" className="pl-4" />
                &nbsp;
                <h2 className="mb-0">
                  Email + CRM + Unified Inbox <span className="primary-color"> for effortless sales growth</span>
                </h2>
              </div>

              <img src={sayGoodbye} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />
              <p>
                Stay focused and 10X your team's productivity. Convert cold leads into clients with ONE centralized
                platform.
              </p>
              <ul>
                <li>Add new leads from anywhere</li>
                <li>Built-in CRM to manage unlimited contacts</li>
                <li>Send personalized emails at scale</li>
                <li>Automate smart follow-ups </li>
                <li>Access all replies via a Unified inbox </li>
              </ul>
            </Col>
            <Col lg={6} className="d-none d-lg-flex">
              <img src={sayGoodbye} alt="Hexospark.com" className="section-image" />
            </Col>
          </Row>
        </Section>
        <Section light>
          <Row className="align-center justify-center">
            <Col lg={6} className="d-none d-lg-flex">
              <img src={findLeads} alt="Hexospark.com" className="section-image" />
            </Col>{' '}
            <Col className="col-12 col-lg-6 align-self-center">
              <div className="d-lg-flex mb-3 text-mobile ">
                <img src={lego} alt="lego" className="pl-4" />
                &nbsp;
                <h2 className="mb-0">
                  <div>
                    Find
                    <span className="primary-color"> unlimited leads </span>
                  </div>
                  <div>from LinkedIn</div>
                </h2>
              </div>
              <img src={findLeads} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />
              <p className="">
                Capture leads and craft engaging emails in seconds with the Hexospark browser extension.
              </p>
              <ul>
                <li>1-Click lead import to CRM and email campaigns</li>
                <li>Create personal icebreakers based on fresh profile data</li>
                <li>Grow your network with the best data in the industry</li>
                <li>Get in the inbox with validated email discovery</li>
              </ul>
            </Col>
          </Row>
        </Section>
        <Section lightGrey>
          <Row className="align-center justify-center">
            <Col className="col-12 col-lg-6 align-self-center">
              <div className="d-lg-flex mb-3 text-mobile">
                <img src={nurtureLeads} alt="lego" className="pl-4" />
                &nbsp;
                <h2 className="mb-0">
                  <div>
                    Nurture <span className="primary-color"> unlimited contacts </span>
                  </div>
                  <div>in your CRM</div>
                </h2>
              </div>

              <img src={nurture} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />
              <p>No lead left behind…Track your leads, notes, and communications with an all-in-one CRM.</p>
              <ul>
                <li>Import leads from anywhere</li>
                <li>Save documents and contracts</li>
                <li>Access email and activity history</li>
                <li>Pipeline management to grow your sales </li>
              </ul>
            </Col>
            <Col lg={6} className="d-none d-lg-flex">
              <img src={nurture} alt="Hexospark.com" className="section-image" />
            </Col>
          </Row>
        </Section>
        <Section light>
          <Row className="align-center justify-center">
            <Col lg={6} className="d-none d-lg-flex">
              <img src={scale} alt="Hexospark.com" width="100%" className="section-image" />
            </Col>
            <Col lg={6} className="col-12  col-lg-6  align-self-center justify-content-left text-left">
              <div className="d-lg-flex mb-3 text-mobile">
                <img src={person} alt="lego" className="pl-4" />
                &nbsp;
                <h2 className="mb-0 pl-10 d-block nowrap">
                  1-1 <span className="primary-color">Personalization</span> at scale
                </h2>
              </div>
              <img src={scale} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />
              <p>Spark conversations and get replies with highly personalized emails that scale.</p>
              <ul>
                <li>Customize emails with lead data</li>
                <li>Write personal icebreakers</li>
                <li>Tap into custom variables</li>
                <li>Rotate email accounts inside campaigns</li>
                <li>Engagement stats for better campaigns</li>
              </ul>
            </Col>{' '}
          </Row>
        </Section>
        <Section lightGrey>
          <Row className="align-center justify-center">
            <Col lg={6} className="align-self-center justify-content-left text-left">
              <div className="d-lg-flex mb-3 text-mobile">
                <img src={automate} alt="lego" className="pl-4" />{' '}
                <h2 className="mb-0 pl-10 d-block nowrap">
                  Automate <span className="primary-color">follow-ups</span>
                </h2>
              </div>
              <img src={save} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />

              <p>Free your time with automated and highly personalized follow-ups</p>
              <ul>
                <li>Schedule follow-ups at different time intervals</li>
                <li>Create sequences based on how leads interact with your email</li>
                <li>Take over seamlessly when leads respond</li>
                <li>Email template library</li>
                <li>Cloud-based cold email and outreach software</li>
              </ul>
            </Col>{' '}
            <Col lg={6} className="d-none d-lg-flex">
              <div className="automate-img-wrapper">
                <img src={save} alt="Hexospark.com" className="section_image" />
              </div>
            </Col>{' '}
          </Row>
        </Section>
        <Section light>
          <Row className="align-center justify-center">
            <Col lg={6} className="d-none d-lg-flex">
              <img src={compose} alt="Hexospark.com" width="100%" className="section-image" />
            </Col>
            <Col lg={6} className="col-12  col-lg-6  align-self-center justify-content-left text-left">
              <div className="d-lg-flex mb-3 text-mobile">
                <img src={unified} alt="lego" className="pl-4" />
                &nbsp;
                <h2 className="mb-0 pl-10 d-block nowrap">
                  <div>
                    <span className="primary-color"> Unified inbox </span>
                    for all{' '}
                  </div>
                  <div>your campaigns</div>
                </h2>
              </div>
              <img src={compose} alt="Hexospark.com" className="d-lg-none my-2 img-mobile section-image" />
              <p>Stay on top of all your replies regardless of how many email accounts you use.</p>
              <ul>
                <li>No more logging into multiple email accounts</li>
                <li>Focus on new replies or access all messages</li>
                <li>Let your team answer replies in real-time</li>
                <li>Access canned responses to speed up replies</li>
                <li>Granular workspace and team settings</li>
              </ul>
            </Col>
          </Row>
        </Section>
        <HomePageIntegrations />
      </div>
    </Layout>
  );
};

export default LIfetimeSpecial;
